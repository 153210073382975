import React from "react";
import "./productSummary.scss";
import productData from "../../assets/product_data/productData.json";
//import PageDesc from "../common/pageDesc/pageDesc";
import PageLabel from "../common/pageLabel/pageLabel";
import WindowWidth from "../common/screen/screen.jsx";
import ScreenSizeContext from "../../shared/context/screen.js";
import { Col, Row, Spinner } from "react-bootstrap";
import _ from "lodash";

export default class ProductSummary extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      productCategory: null,
      productsInCategory: {}
    };
  }
  componentDidMount() {
    // get the url param and upper case it
    const urlParam = this.props.match.params.productType.toUpperCase();

    // check if there is products in the category of the URL param
    const productsInCategory = Object.entries(productData).filter(
      product => product[1].productCategory === urlParam
    );

    if (productsInCategory.length > 0) {
      this.setState({
        productCategory: urlParam,
        productsInCategory: productsInCategory
      });

      // update the active header item
      this.props.additionalProps.props.onUpdateActiveHeaderItem(urlParam);
    }

    // else go to the not found page
    else {
      this.props.history.push("/notFound");
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.productType.toUpperCase() !==
      prevProps.match.params.productType.toUpperCase()
    ) {
      // get the url param and upper case it
      const urlParam = this.props.match.params.productType.toUpperCase();

      // check if there is products in the category of the URL param
      const productsInCategory = Object.entries(productData).filter(
        product => product[1].productCategory === urlParam
      );

      // else go to the not found page
      if (productsInCategory.length > 0) {
        this.setState({
          productCategory: urlParam,
          productsInCategory: productsInCategory
        });
        
        // update the active header item
        this.props.additionalProps.props.onUpdateActiveHeaderItem(urlParam);
      } else {
        this.props.history.push("/notFound");
      }
    }
  }

  // get the title case of the product category for the top bar
  getProductTitleCase() {
    switch (this.state.productCategory) {
      case "JOYSTICKS":
        return "Joysticks Product Page";
      case "LEVELSENSORS":
        return "Level Sensors Product Page";
      case "FOOTPEDALS":
        return "Foot Pedals Product Page";
      case "ROTARYHALLS":
        return "Rotary Hall Product Page";
      case "GRIPS":
        return "Grips Product Page";
      case "ROLLERROCKERS":
        return "Roller Rockers Product Page";
      case "ELECTRONICS":
        return "Electronics Product Page";
      // not used right now
      case "ACTUATORS":
        return "Linear Actuators Product Page";
      default:
        return "Default Product Page";
    }
  }

  getProductDetails(screen) {
    let productsInCategoryChunked;

    // check if its a mobile screen
    if (!screen.mobileScreen) {
      // if the screen width is bigger than 1200px
      if (screen.width > 1200) {
        productsInCategoryChunked = _.chunk(this.state.productsInCategory, 5);
      }
      // else it's smaller than a tablet but bigger than a mobile phone (a phablet)
      else {
        productsInCategoryChunked = _.chunk(this.state.productsInCategory, 2);
      }
      return productsInCategoryChunked.map((product, index) => (
        <Row key={index}>
          {product.map(specificProduct => (
            <React.Fragment key={specificProduct[1].productNum}>
              <Col>
                {/* use the first product picture in the array */}
                <h4>
                  <strong>{specificProduct[1].productDisplayName}</strong>
                </h4>
                <a href={"#/product/" + specificProduct[1].productNum}>
                  <img
                    src={require("../../assets/images/products/" +
                      specificProduct[1].productImages[0])}
                    alt="Product"
                    className={"product-summary-product-images img-fluid"}
                  />
                </a>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      ));
    } else {
      return this.state.productsInCategory.map(product => {
        return (
          <React.Fragment key={product[1].productNum}>
            <Row>
              <Col>
                {/* use the first product picture in the array */}
                <a href={"#/product/" + product[1].productNum}>
                  <img
                    src={require("../../assets/images/products/" +
                      product[1].productImages[0])}
                    alt="Product"
                    className={
                      "product-summary-product-images-mobile img-fluid"
                    }
                  />
                </a>
              </Col>
            </Row>
            <Row>
              <Col className="product-shortDesc">
                <h2>
                  <strong>{product[1].productDisplayName}</strong>
                </h2>
                {product[1].productDescriptionShort}
              </Col>
            </Row>
          </React.Fragment>
        );
      });
    }
  }

  render = () => {
    return (
      <WindowWidth>
        <ScreenSizeContext.Consumer>
          {screen =>
            this.state.productCategory ? (
              !screen.mobileScreen ? (
                <>
                  {/* <PageDesc><p>Building a Joystick can involve many different variables and parts.</p><p>
                  Here you will be able to pick and choose starting with the model number that best fits your project.</p></PageDesc> */}
                  <Row>
                    <Col>
                      <PageLabel>{this.getProductTitleCase()}</PageLabel>
                    </Col>
                  </Row>
                  {this.getProductDetails(screen)}
                </>
              ) : (
                <>
                  <Row>
                    <Col>
                      <PageLabel>{this.getProductTitleCase()}</PageLabel>
                    </Col>
                  </Row>
                  {this.getProductDetails(screen)}
                </>
              )
            ) : (
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                size="lg"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )
          }
        </ScreenSizeContext.Consumer>
      </WindowWidth>
    );
  };
}
