import React, { useEffect, useState } from "react";
import "./homePage.scss";
//import PageLabel from "../common/pageLabel/pageLabel";
import WindowWidth from "../common/screen/screen.jsx";
import ScreenSizeContext from "../../shared/context/screen.js";
import gripShowcase from "../../assets/images/homepage/grip_showcase.png";
import joystickCollection from "../../assets/images/homepage/joystick_collection.png";
import levelSensorCollection from "../../assets/images/homepage/level_collection.png";
import elecShowcase from "../../assets/images/homepage/elec_showcase.png";
import footPedalCollection from "../../assets/images/homepage/footpedal_collection.png";
import gripCollection from "../../assets/images/homepage/grip_collection.png";
import rollerCollection from "../../assets/images/homepage/roller_collection.png";
import rotaryCollection from "../../assets/images/homepage/rotary_collection.png";
import leeBoyCollection from "../../assets/images/homepage/leeboy_collection.png";
import mmWater from "../../assets/images/homepage/mm_water.png";
import jukiBackground from "../../assets/images/homepage/juki_background.png";
import { Button, Carousel, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateActiveHeaderItem } from "../../actions/header-actions";

const HomePage = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(updateActiveHeaderItem("HOME"));
  }, [dispatch]);

  // handle if a URL is not found.
  useEffect(() => {
    if (props.notFound) {
      handleShow();
    }
  }, [props.notFound]);

  // handle the email route.
  useEffect(() => {
    if(props.showEmail){
      window.open('https://apps.rackspace.com/index.php', '_blank'); 
    }
  }, [props.showEmail])

  const notFoundModal = () => {
    return(
      <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Page Not Found</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        We're sorry, the page that you were looking for has either
        moved or has been removed.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    )
  }

  return (
    <WindowWidth>
      <ScreenSizeContext.Consumer>
        {(screen) =>
          !screen.mobileScreen ? (
            <>
              {notFoundModal()}
              <Row>
                <Col>
                  <Carousel>
                    <Carousel.Item>
                      <img
                        src={gripShowcase}
                        className={"span-images img-fluid"}
                        alt="Grip Showcase"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        src={mmWater}
                        className={"span-images img-fluid"}
                        alt="Water"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <div className="bar" />
                </Col>
              </Row>
              <Row className="form-group align-items-end">
                <Col></Col>
                <Col>
                  <h4>
                    <strong>Joysticks</strong>
                  </h4>
                  <div className="side-by-side-images">
                    <a href="#/products/joysticks">
                      <img
                        src={joystickCollection}
                        className={"img-fluid"}
                        alt=""
                      />
                    </a>
                  </div>
                </Col>
                <Col>
                  <h4>
                    <strong>Level Sensors</strong>
                  </h4>
                  <div className="side-by-side-images">
                    <a href="#/products/levelsensors">
                      <img
                        src={levelSensorCollection}
                        className={"img-fluid"}
                        alt=""
                      />
                    </a>
                  </div>
                </Col>
                <Col>
                  <h4>
                    <strong>Roller Rockers</strong>
                  </h4>
                  <div className="side-by-side-images">
                    <a href="#/product/ROLLER_ROCKER">
                      <img
                        src={rollerCollection}
                        className={"img-fluid"}
                        alt=""
                      />
                    </a>
                  </div>
                </Col>
                <Col>
                  <h4>
                    <strong>Rotary Halls</strong>
                  </h4>
                  <div className="side-by-side-images">
                    <a href="#/products/rotaryhalls">
                      <img
                        src={rotaryCollection}
                        className={"img-fluid"}
                        alt=""
                      />
                    </a>
                  </div>
                </Col>
                <Col></Col>
              </Row>
              <Row className="form-group align-items-end">
                <Col></Col>
                <Col>
                  <h4>
                    <strong>Electronics</strong>
                  </h4>
                  <div className="side-by-side-images">
                    <a href="#/products/electronics">
                      <img
                        src={elecShowcase}
                        className={"side-by-side-images img-fluid"}
                        alt=""
                      />
                    </a>
                  </div>
                </Col>
                <Col>
                  <h4>
                    <strong>Grips</strong>
                  </h4>
                  <div className="side-by-side-images">
                    <a href="#/products/grips">
                      <img
                        src={gripCollection}
                        className={"side-by-side-images img-fluid"}
                        alt=""
                      />
                    </a>
                  </div>
                </Col>
                <Col>
                  <h4>
                    <strong>Footpedals</strong>
                  </h4>
                  <div className="side-by-side-images">
                    <a href="#/products/footpedals">
                      <img
                        src={footPedalCollection}
                        className={"side-by-side-images img-fluid"}
                        alt=""
                      />
                    </a>
                  </div>
                </Col>
                <Col>
                  <h4>
                    <strong>Custom Designs</strong>
                  </h4>
                  <div className="side-by-side-images">
                    <a href="#/contactus">
                      <img
                        src={leeBoyCollection}
                        className={"side-by-side-images img-fluid"}
                        alt=""
                      />
                    </a>
                  </div>
                </Col>
                <Col></Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <div className="bar" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <img src={jukiBackground} className={"img-fluid"} alt="" />
                  <div className="caption">
                    <p>Here to drive the Future of Controls</p>
                    {screen.width >= 1280 ? (
                      <p>
                        <Button
                          variant="primary"
                          size="xl"
                          className="mx-5"
                          href="#/contactus"
                        >
                          Engineering Support
                        </Button>
                        <Button
                          variant="primary"
                          size="xl"
                          className="mx-5"
                          href="#/quality"
                        >
                          Quality Assurance
                        </Button>
                        <Button
                          variant="primary"
                          size="xl"
                          className="mx-5"
                          href="#/technology"
                        >
                          Contactless Tech
                        </Button>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {notFoundModal()}
              <Row>
                <Col>
                  <Carousel>
                    <Carousel.Item>
                      <img
                        src={gripShowcase}
                        className={"span-images img-fluid"}
                        alt="Grip Showcase"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        src={mmWater}
                        className={"span-images img-fluid"}
                        alt="Water"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <div className="bar" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>
                    <strong>Joysticks</strong>
                  </h4>
                  <a href="#/products/joysticks">
                    <img
                      src={joystickCollection}
                      className={"side-by-side-images img-fluid"}
                      alt=""
                    />
                  </a>
                </Col>
                <Col>
                  <h4>
                    <strong>Level Sensors</strong>
                  </h4>
                  <a href="#/products/levelsensors">
                    <img
                      src={levelSensorCollection}
                      className={"side-by-side-images img-fluid"}
                      alt=""
                    />
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>
                    <strong>Roller Rockers</strong>
                  </h4>
                  <a href="#/product/ROLLER_ROCKER">
                    <img
                      src={rollerCollection}
                      className={"side-by-side-images img-fluid"}
                      alt=""
                    />
                  </a>
                </Col>
                <Col>
                  <h4>
                    <strong>Rotary Halls</strong>
                  </h4>
                  <a href="#/products/rotaryhalls">
                    <img
                      src={rotaryCollection}
                      className={"side-by-side-images img-fluid"}
                      alt=""
                    />
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>
                    <strong>Electronics</strong>
                  </h4>
                  <a href="#/products/electronics">
                    <img
                      src={elecShowcase}
                      className={"side-by-side-images img-fluid"}
                      alt=""
                    />
                  </a>
                </Col>
                <Col>
                  <h4>
                    <strong>Grips</strong>
                  </h4>
                  <a href="#/products/grips">
                    <img
                      src={gripCollection}
                      className={"side-by-side-images img-fluid"}
                      alt=""
                    />
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>
                    <strong>Footpedals</strong>
                  </h4>
                  <a href="#/products/footpedals">
                    <img
                      src={footPedalCollection}
                      className={"side-by-side-images img-fluid"}
                      alt=""
                    />
                  </a>
                </Col>
                <Col>
                  <h4>
                    <strong>Custom Designs</strong>
                  </h4>
                  <a href="#/contactus">
                    <img
                      src={leeBoyCollection}
                      className={"side-by-side-images img-fluid"}
                      alt=""
                    />
                  </a>
                </Col>
              </Row>
            </>
          )
        }
      </ScreenSizeContext.Consumer>
    </WindowWidth>
  );
};

export default HomePage;
