import React, {useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import PageLabel from "../common/pageLabel/pageLabel";
import {useDispatch} from 'react-redux';
import {updateActiveHeaderItem} from '../../actions/header-actions';

const HowToOrder = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateActiveHeaderItem("HOWTOORDER"))
  }, [dispatch])
  return (
    <>
      <Row>
        <Col>
          <PageLabel>How To Order</PageLabel>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div>
            <p>
              Orders may be placed direct* from P-Q Controls, or through one of
              our approved distributers. To order direct, please call our
              customer service department at 860.583.6994. To view a list of
              approved distributors, please{" "}
              <a href="#/distributors">click here</a>.
            </p>
            <p>
              *P-Q Controls facilities are &quot;build-to-order&quot; and do not
              accept returns for credit. Standard lead time is two weeks plus
              transit (A.R.O). Minimum order of $100. Expedited services
              (three-day lead) available at additional fees. Please call for
              details.
            </p>
            <ul>
              <li>Lead-Time: Two (2) Weeks A.R.O. </li>
              <li>Prices quoted are firm for 30 days thereafter.</li>
              <li>F.O.B. Plant</li>
              <li>Minimum Billing: $100.00 plus shipping.</li>
              <li>All products built to order</li>
              <li>
                Credit card or COD payment offered to new customers, until
                account has been established <br />
              </li>
            </ul>
            <p>
              <em>
                All prices and conditions are subject to change without notice.
                Orders are subject to P-Q Controls general
                <a href="assets/data/info_terms_revb.pdf">
                  {" "}
                  terms and conditions
                </a>
                .
              </em>
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default HowToOrder;
