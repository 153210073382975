import { connect } from 'react-redux';
import {updateActiveHeaderItem} from '../actions/header-actions';
import App from '../App';

const mapStateToProps = state => {
    return {
        activeHeaderItem:state.HeaderStore.activeHeaderItem,
    }
}

//dispatch to update store with new items
const mapDispatchToProps = dispatch => {
    return {
        onUpdateActiveHeaderItem: activeHeaderItem => {
            dispatch(updateActiveHeaderItem(activeHeaderItem))
        }
    }
}

const VisibleAppComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default VisibleAppComponent;