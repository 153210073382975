import React, {useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import PageLabel from "../common/pageLabel/pageLabel";
import prop65Image from '../../assets/images/prop65.png';
import {useDispatch} from 'react-redux';
import {updateActiveHeaderItem} from '../../actions/header-actions';


const Prop65 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateActiveHeaderItem("PROP65"))
  }, [dispatch])
  return (
    <>
      <Row>
        <Col>
          <PageLabel>Prop 65 Compliance</PageLabel>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div>
            <p>
              All companies that sell products in the State of California need
              to comply with California’s Proposition 65, also known as the Safe
              Drinking Water and Toxic Enforcement Act of 1986. PQ Controls is
              now in compliance with this law. The intent of Proposition 65 is
              to protect drinking water sources from being contaminated with
              chemicals known to cause cancer, birth defects or other
              reproductive harm. It requires businesses to inform people about
              exposure to such chemicals. The State of California has currently
              identified a list of more than 900 chemicals that are known to
              cause cancer or reproductive harm. You can access and search this
              list at the OEHHA (Office of Environmental Health Hazard
              Assessment) website:
              https://oehha.ca.gov/proposition-65/proposition-65-list The
              Proposition 65 list of chemicals is extensive and very specific.
              Many of the chemicals used in manufacturing processes are on this
              list. For example: electronics, nickel, rubber, and plated metals
              in our products may have small amounts of some of these chemicals.
              So do cell phones, food and beverages (especially alcoholic
              beverages), cigarettes and furniture! Although there is a “safe
              harbor” level that is defined by OEHHA for each listed chemical,
              there is no certain way for a manufacturer to know whether a
              product qualifies for the “safe harbor” standard. Therefore, PQ
              Controls has created a simple warning label, shown below, that
              will be placed on our products to ensure compliance with the
              California law. We are committed to the safety and well-being of
              our employees and customers, in California and the rest of the
              world.
            </p>
            <img src={prop65Image} alt="prop65Image"></img>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Prop65;
