import React, {useEffect} from "react";
import { Col, Row, Table } from "react-bootstrap";
import PageLabel from "../common/pageLabel/pageLabel";
import valveConns from "../../assets/images/valves/valve_connections.jpg";
import {useDispatch} from 'react-redux';
import {updateActiveHeaderItem} from '../../actions/header-actions';

const ValveVerification = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateActiveHeaderItem("VALVEVERIFICATION"))
  }, [dispatch])

  return (
    <>
      <Row>
        <Col>
          <PageLabel>Hydraulic Valve Verification</PageLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered size="sm">
            <tbody>
              <tr>
                <td>
                  <h3>
                    Which Hydraulic Valves are your products compatible with?
                  </h3>
                </td>
                <td>
                  <p>
                    If there is a valve on the market, chances are P-Q Controls
                    is able to control it. The following is a list of the more
                    common valve manufacturer's that we have configured our
                    controls for;
                  </p>
                  <Table borderless size="sm">
                    <tbody>
                      <tr>
                        <th colSpan="4">
                          Please fill out our
                          <a href="assets/data/valve_verification.pdf">
                            {" "}
                            Valve Verification Form{" "}
                          </a>
                          to help us match your valve
                        </th>
                      </tr>
                      <tr>
                        <td>
                          Angola
                          <br />
                          Apitec
                          <br />
                          Atos <br />
                          Barmag <br />
                          Berringer <br />
                          Bertea <br />
                          Bosch <br />
                          Commercial(SLI) <br />
                          Compact Controls <br />
                          Component Eng. <br />
                          Continental Hydr.
                        </td>
                        <td>
                          Control Hydraulics <br />
                          Danfoss (Sauer) <br />
                          Dennison <br />
                          Dynex <br />
                          Eaton
                          <br />
                          Electro Hyd Controls
                          <br />
                          Fema <br />
                          FPS <br />
                          Generic M120 vdb <br />
                          Gresen(Dana)
                          <br />
                          HSC <br />
                        </td>
                        <td>
                          Husco <br />
                          Hydraforce <br />
                          Hydraulik Ring <br />
                          Hydreco <br />
                          Hydrolux <br />
                          Linde <br />
                          Modular Controls <br />
                          Monsun Tison <br />
                          Moog <br />
                          Nachi <br />
                          Parker
                        </td>
                        <td>
                          Racine <br />
                          Rexroth <br />
                          Sauer-Danfoss <br />
                          Sterling <br />
                          Sundstrand <br />
                          Vickers <br />
                          VOAC <br />
                          Volvo <br />
                          Walvoil <br />
                          Wandfluh <br />
                          Waterman(Parker)
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>Do I need a PQ Valve Drive Board as well?</h3>
                </td>
                <td>
                  <p>
                    If your valve does <em>not</em> have integrated electronics
                    (i.e., on-board valve driver), then a PQ Valve Drive Board
                    is likely required. For more information on our line of
                    drivers, please <a href="electronics.php">click here</a>.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    What are the benefits/disadvantages of a valve with
                    integrated electronics?
                  </h3>
                </td>
                <td>
                  <p>
                    The benefit of valves with on-board electronics is that the
                    drivers are already integrated with the valve, which
                    eliminates the need to externally mount the driver. The
                    disadvantage is that the electronic driver is specific to
                    that valve, and cannot be used to drive other valves.
                    <br />A P-Q Valve Drive Board, however, can be adjusted
                    (in-field) to drive virtually any valve on the market. Thus,
                    a customer can easily swap to a different valve (or replace
                    a defective one) without having to buy new electronics to
                    drive the new valve. It is also worth noting that on-board
                    electronics limits the customer in his options for a command
                    source (since not all joystick companies produce joysticks
                    that meet the input requirements of certain on-board
                    drivers). Using a P-Q Valve Drive Board guarantees a
                    compatible marriage to our controls.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    I'm new to electro-hydraulics. What else do I need to know
                    to set up a system?
                  </h3>
                </td>
                <td>
                  <p>
                    As a general rule, each proportional valve is a single axis
                    of control (e.g., Forward/Reverse is one axis, Left/Right is
                    one axis, and Boom Up/Down is one axis), which in turn
                    requires its own driver and single axis joystick/control. A
                    multi axis joystick may be used to control multiple valves
                    with one hand.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    The following is an illustration of what a typical setup
                    would look like; (1) P-Q Valve Drive Board, (1) Single-Axis
                    Joystick, and (1) Valve.
                  </h3>
                </td>
                <td>
                  <Table borderless size="sm" striped="false">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src={valveConns}
                            className={"img-fluid"}
                            alt=""
                          />
                        </td>
                        <td>
                          <p>
                            <strong>PWM A/B</strong> - Pulse Width Modulation
                            signal output for the A-direction and B-direction
                            (normally the A-direction is positive/forward, and
                            the B-direction is negative/reverse) <br />
                            <strong>AUX A/B</strong> - Auxiliary output for the
                            A-direction and B-direction (these are digital
                            on/off switches used to control miscellaneous
                            functions)
                            <br />
                            <strong>COM</strong> - Common Auxiliary Output
                            (equivalent to jumpering Aux A&amp;B)
                            <br />
                            <strong>PT+,PT-,SIG</strong> - These connect to your
                            command source, which in this case is a standard
                            M115 Hall Effect Joystick. PT+ powers the joystick,
                            which in turn sends a variable signal back to the
                            board.{" "}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ValveVerification;
