import React, {useEffect} from "react";
import "./quality.scss";
import PageLabel from "../common/pageLabel/pageLabel";
import { Col, Row } from "react-bootstrap";
import essegiSystem from "../../assets/images/quality/essegi_system_service_juki.png";
import chamber from "../../assets/images/quality/chamber.png";
import juki from "../../assets/images/quality/juki_machine.png";
import {useDispatch} from 'react-redux';
import {updateActiveHeaderItem} from '../../actions/header-actions';

const Quality = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateActiveHeaderItem("QUALITY"))
  }, [dispatch])
  return (
    <>
      <Row>
        <Col>
          <PageLabel>
            We Strive for the Quality You're Looking for in Controls
          </PageLabel>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-flex-start">
        <Col>
          <div>
            <h1>Quality Assurance</h1>
          </div>
        </Col>
        <Col>
          <div className="title-bar" />
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <p align="left">
              All products designed by P-Q Controls have undergone exhaustive
              testing to insure a robust end-product that will meet the most
              demanding applications. That is why we have invested heavily in an
              anechoic chamber, TEM cell, and corollary electronic equipment.
              Using this this equipment we can insure our products meet strict
              CE regulations for electromagnetic interference immunity.
            </p>
            <p align="left">
              "No maintenance is required for owners of equipment incorporating
              a P-Q Controls Hall Effect. The electronics are epoxy-potted to
              withstand shock, vibration, and the elements. The mechanics never
              require lubrication and the magnets used are the best available,
              with a life of approximately 1,000 years."
              <br />
              <i>-Lift Equipment Magazine, September 2000 Issue</i>
            </p>
          </div>
          <div>
            <img src={essegiSystem} className={"text-incol img-fluid"} alt="" />
          </div>
        </Col>
        <Col>
          <div>
            <img src={chamber} className={"text-incol img-fluid"} alt="" />
          </div>
          <div>
            <h1>Automated Assembly Check Points</h1>
          </div>
          <div>
            <p align="left">
              All production lines incoorporate two or three independent test
              point stations (commonly known as "pre-test" and "final-test"),
              insuring a high quality end product that is fully functional and
              calibrated to strict specifications prior to shipment. Units are
              monitored for potential trends in failures, which help to further
              reduce rate of return. Our dedication to quality has resulted in a
              product that sees a virtual zero failure rate.
            </p>
            <p align="left">
              This engineering expertise has established P-Q Controls as a
              trusted supplier for rugged products designed to last the life of
              their machine. Customers who are seeking a no-maintenance
              plug-and-play solution may look no further than P-Q Controls as a
              reliable source for all their control needs.
            </p>
            <p align="left">
              <a href="#/technology">Advanced Engineering Technology</a> - Learn
              how P-Q Controls has been an established leader in providing
              intelligent engineering solutions.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <div className="bar" />
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <img src={juki} className={"text-mid img-fluid"} alt="" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="bar" />
        </Col>
        <Col>
          <div>
            <h2>We Are</h2>
          </div>
        </Col>
        <Col>
          <div className="bar" />
        </Col>
        <Col>
          <div>
            <h2>We Have</h2>
          </div>
        </Col>
        <Col>
          <div className="bar" />
        </Col>
      </Row>
      <Row>
        <Col />
        <Col>
          <div>
            <p align="center">Compliant to ISO 9001 Standards</p>
          </div>
        </Col>
        <Col />
        <Col>
          <div>
            <p align="center">
              Stringent manufacturing standards to ensure long service and
              trouble-free performance
            </p>
          </div>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col>
          <div className="bar" />
        </Col>
        <Col>
          <div>
            <h2>We Maintain</h2>
          </div>
        </Col>
        <Col>
          <div className="bar" />
        </Col>
        <Col>
          <div>
            <h2>We Utilize</h2>
          </div>
        </Col>
        <Col>
          <div className="bar" />
        </Col>
      </Row>
      <Row className="">
        <Col />
        <Col>
          <div>
            <p align="center">
              Excellence in serving high volume customers and meeting special
              service requirements
            </p>
          </div>
        </Col>
        <Col />
        <Col>
          <div>
            <p align="center">
              Trouble-shooting diagnostics to head off problems and reduce
              downtime
            </p>
          </div>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col>
          <div>
            <h1>On-Time Shipping</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <p>
              P-Q Control's production line is capable of meeting the
              requirements of any order, from bulk quantities for large OEM's,
              to single-piece orders from end-users. Our excellence in service
              has earned P-Q Controls a reputation in the industry for fast lead
              times. Additionally, we understand that sometimes our customers
              may require a rapid build on a bulk order, which is why we offer a
              three-day expedite service. P-Q Controls is also equipped with
              staff on call to work extra shifts if necessary.
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Quality;
