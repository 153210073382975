import React, {useEffect} from 'react';
import { Col, Row, Table } from 'react-bootstrap'
import PageLabel from '../common/pageLabel/pageLabel'
import {useDispatch} from 'react-redux';
import {updateActiveHeaderItem} from '../../actions/header-actions';

const Distributors = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(updateActiveHeaderItem("DISTRIBUTORS"))
    }, [dispatch])
    return (
        <>
            <Row>
                <Col>
                    <PageLabel>Distributors</PageLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th colSpan="3"><h3><strong>North America</strong></h3></th>
                        </tr>
                        <tr>
                            <td>COMPANY</td>
                            <td>LOCATION</td>
                            <td>CONTACT</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>P-Q Controls   Inc.</td>
                            <td>&nbsp;</td>
                            <td>860-583-6994 <br />
                                <a href="contact.php">Contact Us</a></td>
                        </tr>
                        <tr>
                            <td>Fluid System Components</td>
                            <td><p>Green Bay, WI&nbsp;<br />
                                New Berlin, WI </p></td>
                            <td>920-337-0234<br />
                                262-827-2700</td>
                        </tr>
                        <tr>
                            <td>GCC<br /></td>
                            <td>Portland, OR <br />
                                Kent, WA <br />
                                Spokane, WA <br />
                                Tampa, FL</td>
                            <td>503-405-8736<br />
                                253-289-3713<br />
                                509-992-1160<br />
                                813-278-6957<br />
                                <a href="https://gcc-na.com/">gcc-na.com</a></td>
                        </tr>
                        <tr>
                            <td>Hydradyne Hydraulics</td>
                            <td>Oklahoma </td>
                            <td>405-670-3851<br /> 
                            <a href="mailto:okcorders@hydradynellc.com">okcorders@hydradynellc.com</a></td>
                        </tr>
                        <tr>
                            <td>Hydraulic Controls Inc.</td>
                            <td>CA, UT, AZ, CO </td>
                            <td>510-658-8300<br />
                                <a href="mailto:hydcntrl@hydraulic-controls.com">hydcntrl@hydraulic-controls.com</a></td>
                        </tr>
                        <tr>
                            <td>Hydraquip</td>
                            <td>TX <br />
                                LA <br />
                                OK </td>
                            <td>713-680-1951 <br />
                                504-471-0830<br />
                                918-663-3661</td>
                        </tr>
                        <tr>
                            <td >Motion Industries</td>
                            <td  >&nbsp;</td>
                            <td >1-800-526-9328 <br />
                                <a href="http://www.motionindustries.com/motion3/jsp/mii/LocationsMI.jsp?LANGUAGE=0">motionindustries.com</a></td>
                        </tr>
                        <tr>
                            <td >Robbins & Bohr</td>
                            <td >Chattanooga, TN</td>
                            <td >423-756-4430 <br />
                                <a href="http://www.robbinsbohr.com/">robbinsbohr.com</a></td>
                        </tr>
                        <tr>
                            <td >SG Morris </td>
                            <td  >PA<br />
                                OH<br />
                                WV<br /></td>
                            <td >724-779-0010<br />
                                866-746-6774<br />
                                866-746-6774<br />
                                <a href="mailto:sgmmail@sgmorris.com">sgmmail@sgmorris.com</a></td>
                        </tr>
                        <tr>
                            <td>Sunsource</td>
                            <td>AL, GA, LA, TX, AZ, CO, IA, IL, MI, MN, NE, UT, OH </td>
                            <td>1-888-SUN-SRCE<br />
                                <a href="mailto:info@sunsrce.com">info@sunsrce.com</a></td>
                        </tr>
                        <tr>
                            <td >Womack Machine Supply</td>
                            <td  >Dallas, TX<br />
                                Houston, TX <br />
                                Tulsa, OK<br />
                                Salt Lake City, UT<br />
                                Phoenix, AZ<br />
                                Birmingham, AL<br />
                                Billings, MT<br />
                                Missoula, MT</td>
                            <td >800-569-9801<br />
                                800-569-9807<br />
                                800-569-9803<br />
                                801-566-4333<br />
                                480-831-9448<br />
                                205-798-9440<br />
                                800-332-7298<br />
                                800-332-2694<br />
                                <a href="http://www.womackmachine.com">womackmachine.com</a></td>
                        </tr>
                    </tbody>
                </Table>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th colSpan="3"><h3><strong>South America</strong></h3></th>
                        </tr>
                        <tr>
                            <td>COMPANY</td>
                            <td>LOCATION</td>
                            <td>CONTACT</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PQ Controls Ltda. Brazil</td>
                            <td>São Paulo, SP</td>
                            <td>+5511-971437483<br />
                                <a href="contact.php">Contact Us</a></td>
                        </tr>
                    </tbody>
                </Table>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th colSpan="3"><h3><strong>Canada</strong></h3></th>
                        </tr>
                        <tr>
                            <td>COMPANY</td>
                            <td>LOCATION</td>
                            <td>CONTACT</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>P-Q Controls Inc.</td>
                            <td>&nbsp;</td>
                            <td>860-583-6994 <br />
                                <a href="contact.php">Contact Us</a></td>
                        </tr>
                        <tr>
                            <td >Hycotec, Inc</td>
                            <td ><p>Ontario</p></td>
                            <td >905-309-5111</td>
                        </tr>
                        <tr>
                            <td >Sunsource </td>
                            <td >Toronto, Windsor, Western </td>
                            <td >1-888-SUN-SRCE<br />
                                <a href="mailto:info@sunsrce.com">info@sunsrce.com</a></td>
                        </tr>
                        <tr>
                            <td>Wainbee LTEE </td>
                            <td>Canada</td>
                            <td>1-888-WAINBEE<br />
                                <a href="http://www.wainbee.ca">wainbee.ca</a></td>
                        </tr>
                    </tbody>
                </Table>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th colSpan="3"><h3><strong>International</strong></h3></th>
                        </tr>
                        <tr>
                            <td>COMPANY</td>
                            <td>LOCATION</td>
                            <td>CONTACT</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>P-Q Controls   Inc.</td>
                            <td>&nbsp;</td>
                            <td>860-583-6994 <br />
                                <a href="contact.php">Contact Us</a></td>
                        </tr>
                        <tr>
                            <td>Beijing Road Hyd., J&amp;Y Int.</td>
                            <td>China<br /></td>
                            <td>(t) <span lang="EN-US" >+86-10-82800386 </span><br />
                                (f) <span lang="EN-US" >+86-10-82800373</span><br />
                                <span lang="EN-US" ><a href="mailto:sales@road-hydraulic.com">sales@road-hydraulic.com</a></span></td>
                        </tr>
                        <tr>
                            <td>TVH Parts</td>
                            <td>Belgium</td>
                            <td>32 56 43 42 11<br />
                                <a href="mailto:sales@tvh.com">sales@tvh.com</a></td>
                        </tr>
                        <tr>
                            <td>RGDH Hydraulics </td>
                            <td>France</td>
                            <td>00 33 1 64 37 40 25<br />
                                00 33 1 64 37 68   99 <br />
                                <a href="http://www.rgdh.fr">rgdh.fr</a><br />
                                <a href="mailto:cg@rgdh.fr">cg@rgdh.fr</a></td>
                        </tr>
                        <tr>
                            <td>Vertimac</td>
                            <td>Belgium</td>
                            <td>+32 56 77 26 66</td>
                        </tr>
                        <tr>
                            <td>Girish.H.R - SUMUKHA INTERNATIONAL ( I )</td>
                            <td>BANGALORE – 560061, INDIA</td>
                            <td><a href="mailto:sales@sumukhaintl.com">sales@sumukhaintl.com</a><br />
                                Mob : +91-98450 86601<br />
                                Tel : +91-80-40935336<br /></td>
                        </tr>
                    </tbody>

                </Table>

                </Col>
            </Row >
        </>
    )
}

export default Distributors;