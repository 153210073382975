import React from "react";
import "./productPage.scss";
import productData from "../../assets/product_data/productData.json";
import PageLabel from "../common/pageLabel/pageLabel";
import WindowWidth from "../common/screen/screen.jsx";
import ScreenSizeContext from "../../shared/context/screen.js";
import {
  Carousel,
  Col,
  ListGroup,
  Row,
  Table,
  Tab,
  Tabs,
  Spinner
} from "react-bootstrap";
import _ from "lodash";

export default class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      modelNum: null,
      productDetails: {},
      activeTab: "productSpecs"
    };
  }
  componentDidMount() {
    // get the url param and upper case it
    const urlParam = this.props.match.params.modelNum.toUpperCase();

    // check if the product data has this url param
    if (productData.hasOwnProperty(urlParam)) {
      this.setState({
        modelNum: urlParam,
        productDetails: productData[urlParam]
      });
    }
    // else go to the not found page
    else {
      this.props.history.push("/notFound");
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.modelNum.toUpperCase() !==
      prevProps.match.params.modelNum.toUpperCase()
    ) {
      // get the url param and upper case it
      const urlParam = this.props.match.params.modelNum.toUpperCase();
      
      // check if the product data has this url param
      if (productData.hasOwnProperty(urlParam)) {
        this.setState({
          modelNum: urlParam,
          productDetails: productData[urlParam],
          activeTab: "productSpecs"
        });
      }
      // else go to the not found page
      else {
        this.props.history.push("/notFound");
      }
    }
  }

  // get the images for the carousel
  getImages(isMobileScreen) {
    if (!isMobileScreen) {
      return this.state.productDetails.productImages.map(productImage => {
        return (
          <Carousel.Item key={productImage}>
            <img
              src={require("../../assets/images/products/" + productImage)}
              alt="Product"
              className={"product-images img-fluid"}
            />
          </Carousel.Item>
        );
      });
    } else {
      return this.state.productDetails.productImages.map(productImage => {
        return (
          <Carousel.Item key={productImage}>
            <img
              src={require("../../assets/images/products/" + productImage)}
              alt="Product"
              className={"product-images-mobile img-fluid"}
            />
          </Carousel.Item>
        );
      });
    }
  }

  // get the product table data
  getProductTableData() {
    // get the product specs (Currently Mechanical, Electrical, Enviornmental.)
    // only use the ones that have data in them (filter)
    const productSpecs = Object.entries(
      this.state.productDetails.productSpecs
    ).filter(spec => spec[1].length > 0);

    // return the table for use
    // go through each product spec that has data in it
    return productSpecs.map(productSpec => {
      return (
        <React.Fragment key={productSpec[0]}>
          <thead className="table_headers">
            <tr>
              <th>{productSpec[0]}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productSpec[1].map((individualSpec, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{individualSpec[0]}</td>
                  <td>{individualSpec[1]}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </React.Fragment>
      );
    });
  }

  // get the document table data
  getDocTableData() {
    return this.state.productDetails.productDocuments.map(productDocument => {
      return (
        <React.Fragment key={productDocument[1]}>
          <tbody>
            <tr>
              <td>{productDocument[0]}</td>
              <td>
                <a href={"assets/data/" + productDocument[1]}>
                  {productDocument[1]}
                </a>
              </td>
            </tr>
          </tbody>
        </React.Fragment>
      );
    });
  }

  // get the grip table data
  getGripTableData(screen) {
    let productGripsChunked;

    // check if its a mobile screen
    if (!screen.mobileScreen) {
      // if the screen width is bigger than 1200px
      if (screen.width > 1200) {
        productGripsChunked = _.chunk(this.state.productDetails.gripStyles, 3);
      }
      // else it's smaller than a tablet but bigger than a mobile phone (a phablet)
      else {
        productGripsChunked = _.chunk(this.state.productDetails.gripStyles, 2);
      }
      return productGripsChunked.map((grip, index) => (
        <Row key={index}>
          {grip.map(specificGrip => (
            <React.Fragment key={productData[specificGrip].productNum}>
              <Col>
                {/* use the first product picture in the array */}
                <h4>
                  <strong>
                    {productData[specificGrip].productDisplayName}
                  </strong>
                </h4>
                <a href={"#/product/" + productData[specificGrip].productNum}>
                  <img
                    src={require("../../assets/images/products/" +
                      productData[specificGrip].productImages[0])}
                    alt="Product"
                    className={"grip-images img-fluid"}
                  />
                </a>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      ));
    } 
    // the else is not currently used here, we are not showing this
    // on mobile screens
    else {
      return this.state.productDetails.gripStyles.map(specificGrip => {
        return (
          <React.Fragment key={productData[specificGrip].productNum}>
            <Row>
              <Col>
                {/* use the first product picture in the array */}
                <a href={"#/product/" + productData[specificGrip].productNum}>
                  <img
                    src={require("../../assets/images/products/" +
                      productData[specificGrip].productImages[0])}
                    alt="Product"
                    className={"grip-images-mobile img-fluid"}
                  />
                </a>
              </Col>
            </Row>
          </React.Fragment>
        );
      });
    }
  }

  getProductDetails() {
    // get the product details and return it as a list item
    return this.state.productDetails.productDetails.map(
      (productDetail, index) => {
        return (
          <React.Fragment key={index}>
            <ListGroup.Item>-{productDetail}</ListGroup.Item>
          </React.Fragment>
        );
      }
    );
  }

  render = () => {
    return (
      <WindowWidth>
        <ScreenSizeContext.Consumer>
          {screen =>
            this.state.modelNum ? (
              !screen.mobileScreen ? (
                <>
                  <Row>
                    <Col>
                      <PageLabel>
                        {this.state.productDetails.productDisplayName} Product
                        Page
                      </PageLabel>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      {this.state.productDetails.productImages &&
                      this.state.productDetails.productImages.length > 1 ? (
                        <Carousel>{this.getImages()}</Carousel>
                      ) : (
                        <img
                          src={require("../../assets/images/products/" +
                            this.state.productDetails.productImages[0])}
                          alt="Product"
                          className={"product-images img-fluid"}
                        />
                      )}
                    </Col>
                    <Col>
                      <Tabs
                        defaultActiveKey="productSpecs"
                        id="productInfo"
                        activeKey={this.state.activeTab}
                        onSelect={selectedTab =>
                          this.setState({
                            activeTab: selectedTab
                          })
                        }
                      >
                        <Tab eventKey="productSpecs" title="Product Specs">
                          <Table striped bordered hover size="sm">
                            {this.getProductTableData()}
                          </Table>
                        </Tab>
                        {this.state.productDetails.productDocuments &&
                        this.state.productDetails.productDocuments.length >
                          0 ? (
                          <Tab eventKey="productDocs" title="Product Documents">
                            <Table striped bordered hover size="sm">
                              {this.getDocTableData()}
                            </Table>
                          </Tab>
                        ) : (
                          ""
                        )}
                        {this.state.productDetails.productCategory ===
                          "JOYSTICKS" &&
                        this.state.productDetails.gripStyles &&
                        this.state.productDetails.gripStyles.length > 0 ? (
                          <Tab eventKey="productGrips" title="Available Grips">
                            <div className="grip-tab">
                              {this.getGripTableData(screen)}
                            </div>
                          </Tab>
                        ) : (
                          ""
                        )}
                      </Tabs>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup variant="flush">
                        {this.getProductDetails()}
                      </ListGroup>
                    </Col>
                    <Col className="border-left border-left-width border-primary productDescription">
                      {this.state.productDetails.productDescription}
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col>
                      <PageLabel>
                        {this.state.productDetails.productDisplayName} Product
                        Page
                      </PageLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.state.productDetails.productImages &&
                      this.state.productDetails.productImages.length > 1 ? (
                        <Carousel>
                          {this.getImages(screen.mobileScreen)}
                        </Carousel>
                      ) : (
                        <img
                          src={require("../../assets/images/products/" +
                            this.state.productDetails.productImages[0])}
                          alt="Product"
                          className={"product-images-mobile img-fluid"}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="productDescription">
                      {this.state.productDetails.productDescription}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Tabs defaultActiveKey="productSpecs" id="productInfo">
                        <Tab eventKey="productSpecs" title="Product Specs">
                          <Table striped bordered hover size="sm">
                            {this.getProductTableData()}
                          </Table>
                        </Tab>
                        {this.state.productDetails.productDocuments &&
                        this.state.productDetails.productDocuments.length >
                          0 ? (
                          <Tab eventKey="productDocs" title="Product Documents">
                            <Table striped bordered hover size="sm">
                              {this.getDocTableData()}
                            </Table>
                          </Tab>
                        ) : (
                          ""
                        )}
                      </Tabs>
                    </Col>
                  </Row>
                </>
              )
            ) : (
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                size="lg"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )
          }
        </ScreenSizeContext.Consumer>
      </WindowWidth>
    );
  };
}
