import React from "react";
import { Col, Row } from "react-bootstrap";

const PageLabel = props => {
  return (
    <Row className="align-items-center">
      <Col>
        <div className="bar" />
      </Col>
      <Col>
        <div>
          <h1>{props.children}</h1>
        </div>
      </Col>
      <Col>
        <div className="bar" />
      </Col>
    </Row>
  );
};

export default PageLabel;
