import React, {useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import PageLabel from "../common/pageLabel/pageLabel";
import {useDispatch} from 'react-redux';
import {updateActiveHeaderItem} from '../../actions/header-actions';

const Warranty = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateActiveHeaderItem("WARRANTY"))
  }, [dispatch])
  return (
    <>
      <Row>
        <Col>
          <PageLabel>Warranty Returns</PageLabel>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div>
            <p>
              The warranty period for all products is one (1) year from the date
              of shipment to the original customer. Products found to be
              defective within the warranty period will be replaced free of
              charge.
            </p>
            <p>
              PQ Controls, Inc. does not service non-warranty products.&nbsp;
              Instead, we have a replacement program, in which we offer a 25%
              discount on a replacement, providing that the non-warranty product
              is sent to us for proper disposal.
            </p>
            <p>
              Non-warranty products that are returned as replacements are to be
              evaluated at our discretion and evaluation information is kept in
              the Return Materials Authorization Database for reference. Once
              the product is evaluated, it is disassembled and properly
              discarded.{" "}
            </p>
            <p>
              To initiate a warranty return please<strong> c</strong>ontact our
              Warranty / Non-warranty Returns Department via phone 860.583.6994
              to request a RMA# (Return Materials Authorization Number), or{" "}
              <a href="#/contactus">click here</a> for our Online RMA form. Please
              have your model number and serial number ready, as well as a clear
              description of the problem.
            </p>
            <p>&nbsp;The following additional provisions apply:</p>
            <ul>
              <li>
                Package must be clearly written with RMA# on the outside or on
                internal packing slip. Package without RMA# written on the
                outside or on internal packing slip may be refused, returned to
                the sender, or delayed in processing time.{" "}
              </li>
              <li>
                Confirmation of the shipment date of each product is required
                for all warranty repairs. Product with missing or obliterated
                serial numbers or date codes may be disqualified for warranty
                replacement.
              </li>
              <li>
                Expedited service with a three day turn-around is available at
                an additional cost, if production time is available. The
                expedite fee is $250/unit.{" "}
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Warranty;
