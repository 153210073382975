/*
 * action types
 */
export const UPDATE_ACTIVE_HEADER_ITEM = "UPDATE_ACTIVE_HEADER_ITEM";
/*
 * action creators
 */
export function updateActiveHeaderItem(activeHeaderItem) {
  return { type: UPDATE_ACTIVE_HEADER_ITEM, activeHeaderItem };
}
