import React from "react";
import customAlerts from "../../../assets/customAlerts.json";
import { Alert, Col, Row } from "react-bootstrap";

/**
 * Custom Alert
 *
 * Access it this way:
 * import CustomAlert from "./components/common/customAlert/customAlert.jsx"
 *
 * @param {boolean} isActive (required) Show or hide the alert
 * @param {string} heading (required) Alert Heading
 * @param {string} text (required) Text of the alert
 * @param {object} image (optional)
 * @param {string} fileName (optional) Image file name, required if including an image
 * @param {string} imageAlt (optional) Image alt text, required if including an image
 * @param {string} imageWidth (optional) Image width, required if including an image
 * @param {string} imageHeight (optional) Image height, required if including an image
 * @param {string} link (optional) Image link, required if including an image
 */
export default class CustomAlert extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      otherstuff: null,
      activeAlerts: {},
    };
  }

  dateCompare(startDate, endDate) {
    const today = new Date();
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    // check if the schedule falls in between today's date
    if (startDate <= today && today <= endDate) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    // check for active alerts
    const activeAlerts = Object.entries(customAlerts).filter((alert) =>
      this.dateCompare(alert[1].schedule.startDate, alert[1].schedule.endDate)
    );

    if (activeAlerts.length > 0) {
      this.setState({
        activeAlerts: activeAlerts,
      });
    }
  }

  showActiveAlerts() {
    return this.state.activeAlerts.map((activeAlert) => (
      <Alert
        key={activeAlert[0]}
        variant="info"
        // onClose={() =>
        //   this.setState(prevState => ({
        //     activeAlerts: {
        //       ...prevState.activeAlerts,
        //       [activeAlert[0]]: {
        //         ...prevState.activeAlerts[activeAlert],
        //         dismissed: true
        //       }
        //     }
        //   }))
        // }
        // dismissible
      >
        <Alert.Heading>{activeAlert[1].heading}</Alert.Heading>
        {activeAlert[1].text}
        <br />
        {/* image is optional */}
        {activeAlert[1].image ? (
          <a href={activeAlert[1].image.link} target="new">
            <img
              src={require("../../../assets/images/" +
                activeAlert[1].image.fileName)}
              width={activeAlert[1].image.width}
              height={activeAlert[1].image.height}
              alt={activeAlert[1].image.alt}
            />
          </a>
        ) : (
          ""
        )}
      </Alert>
    ));
  }

  render = () => {
    return (
      <>
        {this.state.activeAlerts.length > 0 ? (
          <Row>
            <Col>{this.showActiveAlerts()}</Col>
          </Row>
        ) : (
          ""
        )}
      </>
    );
  };
}
