import React from 'react';
import './contactUs.scss'
import PageLabel from '../common/pageLabel/pageLabel'
import { Col, Row } from 'react-bootstrap'


export default class ContactUs extends React.Component {
    constructor(props) {
      super(props);
      this.props = props;
    }
    
    componentDidMount() {

        //update the header item
        this.props.additionalProps.props.onUpdateActiveHeaderItem("CONTACTUS")

        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '46998866',
                    formId: '72a3654d-8775-45c2-9d16-c0b73340f9ad',
                    target: '#hubspotForm'
                })
            }
        });
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <PageLabel>Contact Us</PageLabel>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <p><strong>Main Facility </strong><br />
                                95 Dolphin Road <br />
                                Bristol, CT 06010</p>
                        </div>
                    </Col>
                    <Col>
                        <div >
                            <p><strong>Telephone:</strong> 860-583-6994 <br />
                                <strong>Fax:</strong> 860-583-6011</p>
                        </div>
                    </Col>
                    <Col>
                        <div >
                            <p><strong>Production Facility </strong><br />
                                64 Park Street<br />
                                Dover Foxcroft, ME 04426</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <div id="hubspotForm"></div>
                    </Col>
                </Row>
            </>
        );
    }
}
