import React from "react";
import "./rfq.scss";
import PageLabel from "../common/pageLabel/pageLabel";
import { Col, Row } from "react-bootstrap";

export default class RFQ extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {

    //update the header item
    this.props.additionalProps.props.onUpdateActiveHeaderItem("RFQ")

    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "46998866",
          formId: "bc4abb84-eceb-44e8-8e91-e65ca4f7b2a9",
          target: "#hubspotForm"
        });
      }
    });
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <PageLabel>Request For Quote</PageLabel>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div id="hubspotForm"></div>
          </Col>
        </Row>
      </>
    );
  }
}
