import React from 'react';
import ScreenSizeContext from '../../../shared/context/screen'

class WindowWidth extends React.Component {
    constructor () {
        super();
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            mobileScreen: window.innerWidth < 767,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            mobileScreen: window.innerWidth < 767,
        })
    }

    render() {
        return (
            <ScreenSizeContext.Provider
                value={{
                    width: this.state.width,
                    height: this.state.height,
                    mobileScreen: this.state.mobileScreen,
                }}
            >
                {this.props.children}
            </ScreenSizeContext.Provider>
        )
    }
}

export default WindowWidth;