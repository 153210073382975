import React, {useEffect, useState} from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import ScrollToTop from './components/common/scroll/scroll';
import HomePage from './components/homepage/homePage';
import ProductSummary from './components/productSummary/productSummary';
import ProductPage from './components/productPage/productPage';
import ContactUs from './components/contactUs/contactUs';
import Distributors from './components/distributors/distributors';
import AboutUs from './components/aboutUs/aboutUs';
import Technology from './components/technology/technology';
import Quality from './components/quality/quality';
import RFQ from './components/rfq/rfq';
import HowToOrder from './components/howToOrder/howToOrder';
import ValveVerification from './components/valveVerification/valveVerification';
import Warranty from './components/warranty/warranty';
import Prop65 from './components/prop65/prop65';
import analytics from '../src/components/common/analytics/analytics'

const Routes = (additionalProps) => {
    // capture the current route
    const [currentRoute, setCurrentRoute] = useState()

    useEffect(()=>{
        // check to make sure the route is not undefined
        if(currentRoute !== undefined){
            // send the pageview
            analytics.sendPageview(currentRoute)
        }
    },[currentRoute])

    return(
        <Router>
            <ScrollToTop />
            <Switch>
                <Route
                    path="/aboutus"
                    render={props => {setCurrentRoute("/aboutus");
                    return <AboutUs />;}}
                />
                <Route
                    path="/contactus"
                    render={props => {setCurrentRoute("/contactus");
                    return <ContactUs additionalProps={additionalProps}/>;}}
                />
                <Route
                    path="/distributors"
                    render={props => {setCurrentRoute("/distributors");
                    return <Distributors />;}}
                />
                <Route
                    path="/quality"
                    render={props => {setCurrentRoute("/quality");
                    return <Quality />;}}
                />
                <Route
                    path="/technology"
                    render={props => {setCurrentRoute("/technology");
                    return <Technology />;}}
                />
                <Route
                    path="/rfq"
                    render={props => {setCurrentRoute("/rfq");
                    return <RFQ additionalProps={additionalProps}/>;}}
                />
                <Route
                    path="/order"
                    render={props => {setCurrentRoute("/order");
                    return <HowToOrder />;}}
                />
                <Route
                    path="/valves"
                    render={props => {setCurrentRoute("/valves");
                    return <ValveVerification />;}}
                />
                <Route
                    path="/warranty"
                    render={props => {setCurrentRoute("/warranty");
                    return <Warranty />;}}
                />
                <Route
                    path="/prop65"
                    render={props => {setCurrentRoute("/prop65"); 
                    return <Prop65 />;}}
                />
                <Route
                    path="/products/:productType"
                    render={props => {setCurrentRoute(props.location.pathname);
                    return <ProductSummary {...props} additionalProps={additionalProps} />;}}
                />
                <Route
                    path="/product/:modelNum"
                    render={props => {setCurrentRoute(props.location.pathname);
                    return <ProductPage {...props} additionalProps={additionalProps}/>;}}
                />
                <Route
                    path="/email"
                    render={props => {setCurrentRoute("/email");
                    return <HomePage showEmail={true}/>;}}
                />
                <Route
                    path="/"
                    exact
                    render={props => {setCurrentRoute("/");
                    return <HomePage />;}}
                />
                {/* Handle any not found routes */}
                <Route render={props => {setCurrentRoute(props.location.pathname);
                return <HomePage notFound={true} />;}} />
            </Switch>
        </Router>
    )
}

export default Routes;