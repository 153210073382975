import React from "react";
import "./footer.scss";
import { Col, Row, Table } from "react-bootstrap";
import WindowWidth from "../screen/screen.jsx";
import ScreenSizeContext from "../../../shared/context/screen.js";

export default function Footer() {

  let d = new Date();
  return (
    <WindowWidth>
      <ScreenSizeContext.Consumer>
        {screen => (
          <>
            <Row style={{  marginRight: "0px", marginLeft: "0px"}}>
              <Col style={{ paddingRight: "0", paddingLeft: "0"}}>
                <Table style={{ height: "150px", backgroundColor: "#00578E" }}>
                  <tbody>
                    <tr>
                      <td
                        className="align-middle"
                        style={{ color: "#FFFFFF", fontSize: "40px" }}
                      ></td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <br></br>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                Copyright &copy;{d.getFullYear()} P-Q Controls, Inc. All rights
                reserved.
              </Col>
            </Row>
          </>
        )}
      </ScreenSizeContext.Consumer>
    </WindowWidth>
  );
}
