import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/app.scss';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducers from "./reducers";
import VisibleAppComponent from './containers/appContainer';
import analytics from '../src/components/common/analytics/analytics';


//initialize the analytics library
analytics.init()

const store = createStore(
    reducers,
);

ReactDOM.render(
    <Provider store={store}>
        <VisibleAppComponent />
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();