import React from 'react';
import Header from './components/common/header/header.jsx'
import Footer from './components/common/footer/footer.jsx'
import CustomAlert from "./components/common/customAlert/customAlert.jsx"
import Routes from './routes'
import { Container } from 'react-bootstrap'
import './App.css';

function App(props) {
  return (
    <div className="App">
      <Container fluid='true'>
        <Header props={props}/>
        <CustomAlert/>
        <Routes props={props}/>
        <Footer />
      </Container>
    </div>
  );
}

export default App;
