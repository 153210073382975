import React, {useEffect} from 'react';
import './aboutUs.scss';
import PageLabel from '../common/pageLabel/pageLabel';
import { Col, Row, ResponsiveEmbed } from 'react-bootstrap';
import vintageTesting from "../../assets/images/about_us/vintage_testing.jpg";
import {useDispatch} from 'react-redux';
import {updateActiveHeaderItem} from '../../actions/header-actions';

const AboutUs = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(updateActiveHeaderItem("ABOUTUS"))
    }, [dispatch])
    return (
        <>
            <Row>
                <Col>
                    <PageLabel>For Over 45 Years...</PageLabel>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col className='text-inline'>
                    <div>
                        <p className="intro"><strong>
                        P-Q Controls has been an industry leader in the outdoor / off-highway controls market. From the large OEM to the end user, the company's diverse product portfolio and expert engineering services continue to supply the world's market of heavy duty joysticks and controls. Typical applications include; mining, construction, aerial lifts, refuse, fire apparatus, forestry, and simulators.
                        </strong></p>
                    </div>
                </Col>
                <Col>
                    <img src={vintageTesting} className={'text-inline img-fluid'} alt=''/>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col>
                    <div className="bar" />
                </Col>
                <Col>
                    <div>
                        <h1>
                            Designed to Last
                        </h1>
                    </div>
                </Col>
                <Col>
                    <div className="bar" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <p align="left">
                            P-Q Controls was established in 1973 as a producer and supplier of industrial joysticks and controls, and has since grown into one of the largest manufacturers of its kind. Today, P-Q Controls continues its record of steady annual growth, allowing us to serve a greater array of equipment manufacturers. Our long-standing history in the design of contactless solid-state sensing, has allowed us to improve our products over time to near-perfect quality, giving us a marked advantage over our competition. The result for the end-user is quite simply:  When you buy from P-Q Controls, you are not simply buying a product. You are buying a product that is the result of over 40 years of engineering development.
                        </p>
                        <p align="left">
                            Our pioneering efforts in developing Inductively Coupled, <a href='#/technology'>Hall Effect</a>, and other contactless controls have earned us the industry's leadership position. In fact, P-Q Controls holds an exclusive patent for the Inductively Coupled Contactless control, and are known throughout the industry for expertise in both Inductive and Hall Effect technologies.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col>
                    <div className="bar" />
                </Col>
                <Col>
                    <div>
                        <h1>
                            Engineered to Perform
                        </h1>
                    </div>
                </Col>
                <Col>
                    <div className="bar" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <p align='left'>
                            We don't rest on our reputation, however. Our goal is to stay ahead of the curve by consistently delivering high quality products and services to meet or exceed the demanding applications of our customers, including; mining, construction, aerial lifts, refuse, de-icers, fire apparatus, forestry, simulators, defense systems, and high tech equipment. No matter what the application, we strive to serve the needs of our customers, whether the large OEM companies or the end-user.
                        </p>
                        <h3 align='left'>
                            Quality
                        </h3>
                        <p align='left'>
                            P-Q Controls takes pride in our ability to meet and exceed our customers requests for JIT services, on time deliveries, economical repairs, and expert engineering services. To read about P-Q's standards of quality, please <a href='#/quality'>click here</a>.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='video-inline'>
                    <div>
                        <ResponsiveEmbed aspectRatio='16by9'>
                            <embed src="https://www.youtube.com/embed/lYrIw3KNEsE" />
                        </ResponsiveEmbed>
                    </div>
                </Col>
                <Col className='video-inline'>
                    <div>
                        <ResponsiveEmbed aspectRatio='16by9'>
                            <embed src="https://www.youtube.com/embed/x-O3TvxY0Gg" />
                        </ResponsiveEmbed>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default AboutUs;