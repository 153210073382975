import React, {useEffect} from 'react';
import './technology.scss';
import PageLabel from '../common/pageLabel/pageLabel';
import { Col, Row } from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {updateActiveHeaderItem} from '../../actions/header-actions';

const Technology = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(updateActiveHeaderItem("TECHNOLOGY"))
    }, [dispatch])
    return (
        <>
            <Row>
                <Col>
                    <PageLabel>Contactless Technology</PageLabel>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col>
                    <div>
                        <h1>
                            The Contactless Mechanisms - Accuracy Without Wear
                        </h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <p align="left">
                            P-Q Controls' patented inductively coupled system used in multi-axis and level attitude sensors eliminates the wiping contacts of potentiometric devices and minimizes the number of moving parts. For certain single-axis and multi-axis joysticks and switched output controls, P-Q uses a design based on the latest Hall Effect technologies. Contactless devices, whether optical, inductive or Hall effect based, will not wear out - a major advantage over mechanical switches.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col>
                    <div>
                        <h1>
                            The New Generation of Technology in Hall Effect
                        </h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <p align='left'>
                            P-Q Controls began research and development in Hall Effect technology in the mid 1980's. What this means, is for the past 30 years, P-Q Controls has invested ample time and research into improving and perfecting this technology. When you buy from P-Q Controls, you can be sure that our products are field proven for long-term outdoor use under virtually any environmental condition.
                        </p>
                        <p align='left'>
                            Hall Effect Technology has grown to be the industy preference in contactless proportional control, and P-Q Controls is right there with you to provide the latest advances in this technology. This simple design exploits the characteristics of electromagnetic fields through the change in position of a small magnet in reference to an electrical current flowing through a conductive material. The result is a precise voltage output that is proportional to the position of the magnet, which is directly dictated by movement of a joystick.
                        </p>
                        <p align='left'>
                            Always staying one step ahead of the competition - that's the secret of our success. Our people are known throughout the industry as experts in programmable control design. If P-Q Controls does not have the technology, we are probably working on it. We are continually upgrading control and motion sensing devices by improving performance, reducing costs and finding new ways to solve your problems. P-Q Controls is one of the foremost innovators in the industry. We have developed programmable controls that increase equipment performance while making operation even safer. Our state-of-the-art, hand-held, teach pendant is a microprocessor based, data input device that advances programmable control technology to a new level. From switched, switchless and multiplex, to fiber optics, and remote radio signal; our multi-faceted product line gives customers the optimum choices for operating their equipment. P-Q's modular product design effectively utilizes our advanced design concepts to meet your custom requirements. Our CAD capabilities and state-of-the-art test equipment assure unmatched product reliability.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col>
                    <div>
                        <h1>
                            Application Engineering and Design Assistance - Built-In Superiority
                        </h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <p align='left'>
                            P-Q Controls has the expertise to meet your current specifications. We allow you the flexibility to improve and upgrade your equipment without reinventing the wheel. Our CAD system assures precision products to solve the toughest control problems and facilitates later modifications or improvements without expensive re-engineering.
                        </p>
                        <p align='left'>
                            You can count on P-Q Controls' engineers to work closely with you to design, modify or develop new applications for specialized controls. Our experts will interpret requirements for controlling or monitoring your equipment and help you achieve peak performance for any application.
                        </p>
                        <p align='left'>
                            Our experienced design engineers are continually seeking ways to improve performance or develop totally new products. Working with the most advanced and sensitive electronic measuring instruments, they are creating cutting edge controls with an eye toward the future. From initial contact, through design and manufacturing, to providing post-installation maintenance troubleshooting - our engineering team is committed to your needs.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col>
                    <div>
                        <h1>
                            I'm new to electro-hydraulics. What else do I need to know to set up a system?
                        </h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <p align='left'>
                        As a general rule, each proportional valve is a single axis of control (e.g., Forward/Reverse is one axis, Left/Right is one axis, and Boom Up/Down is one axis), which in turn requires its own driver and single axis joystick/control. A multi axis joystick may be used to control multiple valves with one hand.
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Technology;