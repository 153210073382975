import ReactGA from "react-ga4"
 
const TRACKING_ID = "G-TFR6V4S887"
 
function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ReactGA.initialize(TRACKING_ID, { debug: isDev })
}
 
function sendEvent(payload) {
  ReactGA.event(payload)
}
 
function sendPageview(path) {
  ReactGA.send({ hitType: "pageview", page: path });
}
 
export default {
  init,
  sendEvent,
  sendPageview,
}