import { UPDATE_ACTIVE_HEADER_ITEM } from '../actions/header-actions';

//initial state
const initialState = {
    activeHeaderItem: "Default"
}

function HeaderReducer(
    state = initialState, action,
){
    switch (action.type){
        case UPDATE_ACTIVE_HEADER_ITEM:
            return { ...state, activeHeaderItem: action.activeHeaderItem};
        default:
            return state;
    }
}

export default HeaderReducer;